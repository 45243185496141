<template>
  <form
    novalidate
    method="post"
    autocomplete="off"
    @submit.prevent="emitValid">
    <div class="columns is-gapless is-formfields">
      <div class="column">
        <p>{{ $t('registration.social.confirmData') }}</p>
        <input-text v-model="email"
          id="email"
          :label="$t('email')"
          name="email"
          scope="fb"
          :validate="emailValidation"
          class="is-medium" />
      </div>
    </div>
    <div class="columns is-gapless is-formfields">
      <div class="column">
        <input-text v-model="nickname"
          id="nickname"
          name="nickname"
          :label="$t('nickName')"
          scope="fb"
          :validate="nicknameValidation"
          class="is-medium" />
      </div>
    </div>
    <div class="control mt-50">
      <div class="button-column-group">
        <button type="submit"
          :disabled="errors.any('fb')"
          class="button is-primary is-outlined is-fullwidth is-medium"
          id="submit-recovery">{{$t('submit')}}</button>
        <button
          @click.prevent="emitCancel"
          style="margin-left: 0px !important"
          class="button mt-20 is-outlined is-fullwidth is-medium is-in-column">
          {{$t('cancel')}}
        </button>
      </div>
      <input type="submit"
        style="position: absolute; left: -9999px">
    </div>
  </form>
</template>

<script>
import modal from '~/components/modal'

export default {
  name: 'socialLoginFormComponent',
  serverCacheKey: () => 'social-login-form-component',
  props: ['user', 'modalRegistration', 'returnRouteObject'],
  components: { modal },
  data: () => ({
    email: '',
    nickname: ''
  }),

  mounted() {
    this.email = this.user.socialData.email
    this.nickname = this.user.socialData.nickname
  },
  computed: {
    emailValidation() {
      return `required|validEmailAddress|uniqueEmailAddress:${this.user.id}`
    },
    nicknameValidation() {
      return `required|uniqueNickname:${this.user.id}`
    }
  },
  methods: {
    async emitValid() {
      const valid = await this.$validator.validate('fb.*')
      if (valid) {
        this.$emit('valid', {
          email: this.email,
          nickname: this.nickname
        })
      }
    },
    clearValidator() {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
    },
    emitCancel() {
      this.clearValidator()
      this.$emit('cancelled')
    }
  }
}
</script>
