<template>
  <span v-if="errors.has(name)"
    class="help is-danger">{{ message }}</span>
</template>

<script>
export default {
  props: ['name', 'label'],
  inject: ['$validator'],
  computed: {
    message() {
      const err = this.errors.first(this.name) || {}
      return err.message || err
    }
  }
}
</script>
