export default {
  email: {
    validEmailAddress: 'This email address is invalid.',
    uniqueEmailAddress: 'This email address is already taken.',
    required: 'The email address is required.'
  },
  nickname: {
    uniqueNickname: 'This name is already taken.'
  },
  password: {
    passwordStrength: 'The password is not yet strong enough.',
    required: 'Your password is required here.'
  },
  bankDetailsOwner: {
    required: 'The name of the bank account holder is required.'
  },
  iban: {
    required: 'The IBAN number is required for payouts.'
  },
  salutation: {
    required: 'Please choose your desired salutation.'
  },
  payoutFirstName: {
    required: 'Please provide us with your name.'
  },
  payoutLastName: {
    required: 'Please provide us with your surname.'
  },
  payoutAddressStreet: {
    required: 'Please provide us with your street.'
  },
  payoutAddressPostcode: {
    required: 'Please provide us with your postcode.'
  },
  payoutAddressCity: {
    required: 'Please provide us with your city.'
  },
  country: {
    required: 'Please provide us with your country.'
  },
  passwordMeter: {
    suggestion: {
      'No need for symbols, digits, or uppercase letters': 'No need for symbols, digits, or uppercase letters.',
      'Use a few words, avoid common phrases': 'Use a few words, avoid common phrases',
      'Add another word or two. Uncommon words are better.': 'Add another word or two. Uncommon words are better.',
      'Avoid repeated words and characters': 'Avoid repeated words and characters.',
      'Use a longer keyboard pattern with more turns': 'Use a longer keyboard pattern with more turns.',
      'Avoid dates and years that are associated with you': 'Avoid dates and years that are associated with you',
      'Avoid recent years': 'Avoid recent years',
      'Avoid years that are associated with you': 'Avoid years that are associated with you',
      "Capitalization doesn't help very much": "Capitalization doesn't help very much",
      'All-uppercase is almost as easy to guess as all-lowercase': 'All-uppercase is almost as easy to guess as all-lowercase',
      "Predictable substitutions like '@' instead of 'a' don't help very much": "Predictable substitutions like '@' instead of 'a' don't help very much"
    },
    warning: {
      'Straight rows of keys are easy to guess': 'Straight rows of keys are easy to guess.',
      'Short keyboard patterns are easy to guess': 'Short keyboard patterns are easy to guess.',
      'Repeats like "aaa" are easy to guess': 'Repeats like "aaa" are easy to guess.',
      'Sequences like abc or 6543 are easy to guess': 'Sequences like abc or 6543 are easy to guess.',
      'Recent years are easy to guess': 'Recent years are easy to guess.',
      'Dates are often easy to guess': 'Dates are often easy to guess',
      'This is a top-10 common password': 'This is a top-10 common password',
      'This is a top-100 common password': 'This is a top-100 common password',
      'This is a very common password': 'This is a very common password',
      'A word by itself is easy to guess': 'A word by itself is easy to guess',
      'Names and surnames by themselves are easy to guess': 'Names and surnames by themselves are easy to guess',
      'Common names and surnames are easy to guess': 'Common names and surnames are easy to guess'
    }
  }
}
