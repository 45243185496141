export default ({ store, app }) => {
  let currentArtistUrlAlias = null
  const domain = (app.i18n.locales.find(
    l => l.domain.split('.').length < 4) || {}).domain || 'getnext.to'

  app.$plausibleGlobal.load(domain)

  app.router.afterEach(({ name }) => {
    const profileBase = name.indexOf('id') === 0
    const artist = store.state.artist || {}
    if (!profileBase || !artist.urlAlias) {
      currentArtistUrlAlias = null
      app.$plausibleSegmented.clean()
      return
    }

    if (currentArtistUrlAlias !== artist.urlAlias) {
      currentArtistUrlAlias = artist.urlAlias
      app.$plausibleSegmented.clean()
      app.$plausibleSegmented.load(`${domain}/${currentArtistUrlAlias}`)
    }
  })
}
