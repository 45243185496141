<template>
  <nav id="mainnav" ref="mainnav" class="navbar is-transparent" v-bind:class="{ mobile: mobileMenuOpen }">
    <div class="container">
      <div class="navbar-menu">
        <div class="navbar-start">
          <a v-if="!$route.name.startsWith('id__') && !$route.name.startsWith('id-')" href="/"
            class="nav-item nav-brand">
            <div class="logo-wrapper">
              <img src="/img/logo_getnext_25_11_18_3.svg" />
            </div>
          </a>
          <nuxt-link v-else-if="hasBrandingUrl" :to="localePath({
            name: 'id',
            params: { id: artist.urlAlias }
          })" class="nav-item nav-brand">
            <div class="brand-wrapper">
              <img :src="brandingSrc" :srcset="brandingSrcSet" :alt="`${artist.nickname} Logo`">
            </div>
          </nuxt-link>
        </div>
        <div class="navbar-item is-expanded is-paddingless ml-20">
          <artist-search @blur="exitSearch" refs="artistSearch" name="desktopSearch" class="artist-search"
            :searchMaximized="searchMaximized" />
        </div>
        <div class="navbar-end">
          <span v-if="
            !isAuthenticated &&
            $route &&
            $route.name &&
            !$route.name.startsWith('portal-login___')
          " @click="showModalLogin" class="navbar-item is-tab is-link" id="login">
            {{ $t('login') }}
          </span>
          <div class="dropdown navbar-item is-tab is-hidden-touch" v-if="
            isAuthenticated &&
            followship &&
            followship.users &&
            followship.users.length &&
            showFollowshipMenu
          " ref="dropdown">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-followed"
                @click.prevent="dropdownToggle">
                <span>{{ $t('followedArtists') }}</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div id="dropdown-followed" class="dropdown-menu" role="menu" @click.capture="dropdownToggle">
              <div class="wrapper">
                <div class="dropdown-content">
                  <portal-target name="desktopFollowship"></portal-target>
                </div>
              </div>
            </div>
          </div>
          <a class="navbar-item is-tab" v-if="isAuthenticated" href="/myprofile" id="myProfileLink">{{ $t('hi') }} {{
            user.nickname }}</a>
          <a class="navbar-item is-tab" v-if="isAuthenticated" @click.prevent="doLogout" id="logoutLink">{{ $t('logOut')
            }}</a>
          <div :class="{
            mobile: mobileMenuOpen
          }" class="language-container">
            <div :class="{
              'navbar-item': !mobileMenuOpen,
              'mobile-language-link': mobileMenuOpen
            }" class="is-link is-tab" v-for="locale in $i18n.locales" @click.prevent="switchLanguage(locale)"
              :key="locale.code">
              {{ locale.code.toUpperCase() }}
            </div>
          </div>

          <div id="burger" :class="{ active: mobileMenuOpen }" class="burger" ref="burger" @click="toggleMobileMenu">
            <span class="upper"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
          </div>
        </div>
      </div>
    </div>
    <mobileNav ref="mobileNav" v-on:logoperation="handleLogOperation" v-bind:class="{ open: mobileMenuOpen }" />
    <div @click.capture="dropdownToggle" :class="{ 'dropdown-backdrop': followBoxOpened }"></div>
    <div @click.capture="exitSearch" :class="{ 'search-backdrop': searchMaximized && !mobileMenuOpen }"></div>
    <portal v-bind:to="followshipTarget">
      <followBox />
    </portal>
    <section v-if="!isAuthenticated && showLogin" class="section">
      <modal @close="closeLoginModal" :backdropClose="!socialRegistration" defaultHeader="true"
        :defaultFooter="!socialRegistration">
        <div slot="header">{{ modalLoginTitle }}</div>
        <div slot="body" class="reward-grow">
          <div class="is-overlay">
            <section class="scale-hack">
              <div class="content">
                <div class="
                    modal-card
                    animated
                    slideInDown
                    login-modal
                    pt-10
                    pl-10
                    pr-10
                  ">
                  <socialLogin @registration="setSocialRegistration" @logged="closeLoginModal" :divider="true">
                  </socialLogin>
                  <div v-if="!socialRegistration">
                    <login @viewChanged="setModalLoginTitle" @logged="closeLoginModal" :params="{
                      isWizard: true
                    }"></login>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </modal>
    </section>
  </nav>
</template>

<style lang="scss">
@import 'assets/sass/_variables';

.profile {
  .artist-search {
    display: none;
  }
}

.dropdown-backdrop,
.search-backdrop {
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

#dropdown-followed {
  max-height: 80vh;
  width: 425px !important;
  overflow: hidden;
  top: 48px;

  .wrapper {
    width: 450px !important;
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.dropdown {
  color: white !important;
}

.isStatic {
  .dropdown {
    color: $navbar-dark-text !important;
  }
}

.is-link {
  cursor: pointer;
}

#mainnav {
  &.light-shaded {
    .dropdown {
      color: $navbar-dark-text !important;
    }
  }
}

.navbar-end {
  .language-container {
    display: inline-flex;

    &.mobile {
      position: absolute;
      top: 11px;
      right: 45px;
    }

    .mobile-language-link {
      font-size: 1.2em;
      margin-right: 25px;
    }
  }
}

.login-modal {
  margin: auto;
}

@media screen and (max-width: $mobile-portrait) {
  body.isProfile #mainnav .claim {
    color: #fb3e52;
  }

  body.isProfile #mainnav .burger span {
    background: #fb3e52 !important;
  }
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import artistSearch from '~/components/artist-search'
import mobileNav from '~/components/mobilenav'
import followBox from '~/components/follow-box'
import login from '~/components/login'
import socialLogin from '~/components/social-login'
import modal from '~/components/modal'

export default {
  components: { artistSearch, mobileNav, followBox, modal, login, socialLogin },

  props: ['name', 'params', 'disabled'],

  provide() {
    return {
      exitSearch: this.exitSearch,
      focusedSearch: this.focusedSearch
    }
  },

  data: () => ({
    q: '',
    hasFocus: false,
    currentRow: -1,
    hits: [],
    mobileLogout: false,
    followBoxOpened: false,
    showLogin: false,
    modalLoginTitle: '',
    socialRegistration: false,
    scrollTo: false,
    searchMaximized: false
  }),

  computed: {
    searchTarget() {
      return this.mobileMenuOpen ? 'mobileSearch' : 'desktopSearch'
    },
    followshipTarget() {
      return this.mobileMenuOpen ? 'mobileFollowship' : 'desktopFollowship'
    },
    showFollowshipMenu() {
      return !this.searchMaximized || ['lgr', 'xl', 'xxl'].includes(this.$mq)
    },
    hasBrandingUrl() {
      return this.artist &&
        this.artist.profile &&
        this.artist.profile.imgs &&
        this.artist.profile.imgs.branding &&
        this.artist.profile.imgs.branding.name
    },
    brandingSrc() {
      return `/services/media/image/${this.artist._id}/${this.artist.profile.imgs.branding.name}/0/40/image.webp`
    },
    brandingSrcSet() {
      return `/services/media/image/${this.artist._id}/${this.artist.profile.imgs.branding.name}/0/80/image.webp 2x, /services/media/image/${this.artist._id}/${this.artist.profile.imgs.branding.name}/0/120/image.webp 3x`
    },
    ...mapState(['user', 'artist', 'isAuthenticated', 'mobileMenuOpen', 'followship'])
  },

  watch: {
    mobileLogout: () => {
      this.toggleMobileMenu()
    }
  },

  methods: {
    async doLogout(options) {
      const data = await this.apiGet('/api/private/logout')
      if (data) {
        this.showLogin = false
        if (!options || !options.localStorageSignal) {
          window.localStorage.setItem('logoutTime', `${Date.now()}`)
        }
        this.logout()
        this.$root.$emit('refreshAPI')
      }
    },

    async handleLogOperation(type) {
      if (type === 'logout') {
        await this.doLogout()
      }
      this.toggleMobileMenu()
    },

    exitSearch() {
      if (this.mobileMenuOpen) {
        this.toggleMobileMenu()
        return
      }
      this.searchMaximized = false
    },

    scrollHandler() {
      const mainnav = this.$refs.mainnav
      const offset = window.scrollY
      if (offset <= 0) mainnav.classList.remove('light-shaded')
      if (offset > 0) mainnav.classList.add('light-shaded')
    },

    widthHandler(e) {
      if (e.currentTarget.innerWidth > 1023 && this.mobileMenuOpen) {
        this.toggleMobileMenu()
      }
    },

    toggleMobileMenu() {
      this.$store.dispatch('toggleMobileMenuOpen')
      document.body.classList.toggle('no-scroll')
    },

    dropdownToggle() {
      this.followBoxOpened = !this.followBoxOpened
      const dropdown = this.$refs.dropdown
      dropdown.classList.toggle('is-active')
    },

    gotoLogin() {
      this.storeReturnRouteObject({
        route: this.$route,
        locales: this.$root.$i18n['locales'],
        locale: this.$i18n.locale
      })
      this.$router.push(this.localePath({ name: 'portal-login' }))
    },

    showModalLogin(options) {
      if (options.scrollTo) {
        this.scrollTo = options.scrollTo
      }
      this.storeReturnRouteObject({
        route: this.$route,
        locales: this.$root.$i18n['locales'],
        locale: this.$i18n.locale
      })
      this.$root.$emit('toggleBodyScrollable')
      this.showLogin = true
    },

    closeLoginModal() {
      this.$root.$emit('toggleBodyScrollable')
      this.showLogin = false
      if (this.scrollTo) {
        this.$scrollTo(this.scrollTo)
      }
    },

    setSocialRegistration(value) {
      this.socialRegistration = value
    },

    setModalLoginTitle(title) {
      this.modalLoginTitle = title
    },

    async switchLanguage(locale) {
      await this.setInterfaceLanguage(locale.code)
      if (process.client && window) {
        window.location.href = this.switchLocalePath(locale.code)
      }
    },

    storageEvents(e) {
      // console.log('received storage event', e)
      if (e.storageArea !== localStorage) {
        return
      }
      if (e.key === 'logoutTime') {
        return this.doLogout({ localStorageSignal: true })
      }
    },

    focusedSearch(val) {
      this.searchMaximized = val
    },

    ...mapActions(['logout', 'storeReturnRouteObject', 'setInterfaceLanguage'])
  },

  mounted() {
    this.modalLoginTitle = this.$t('login')
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('resize', this.widthHandler)
    window.addEventListener('resize', this.widthHandler)
    window.addEventListener('storage', this.storageEvents)

    this.$root.$on('logout', this.doLogout)
    this.$root.$on('showLogin', this.showModalLogin)
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('resize', this.widthHandler)
    window.removeEventListener('storage', this.storageEvents)

    this.$root.$off('logout', this.doLogout)
    this.$root.$off('showLogin', this.showModalLogin)
  }
}
</script>
