export default function ({ route, store }) {
  let role = 'visitor'
  if (store.state.isAuthenticated) {
    role = store.state.user.artist ? 'artist' : 'fan'
  }
  route.meta.matomo = {
    authenticated: ['isUserAuthenticated', store.state.isAuthenticated],
    role: ['setUserRole', role]
  }
}
