// See node_modules/nuxt-i18n/src/templates/middleware.js
export default async function ({
  app,
  // req,
  // res,
  params,
  // route,
  store,
  // redirect,
  isHMR
}) {
}
