import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// Replace this with your project's endpoint

// direct access
// const GRAPHCMS_API = 'https://api-eu-central-1.graphcms.com/v2/cjkhzdd6110pw01gm95vcofcy/master'

// cached by nginx and forwarded to graphcms
// absolute with domain and protocol required to work in SSR!
// const GRAPHCMS_API = 'https://getnext.to/content/'
const GRAPHCMS_API = 'https://api-eu-central-1.graphcms.com/v2/cjkhzdd6110pw01gm95vcofcy/master'

export default () => ({
  link: new HttpLink({ uri: GRAPHCMS_API }),
  cache: new InMemoryCache()
})
