<template>
  <div class="artist-imprint-line has-text-grey-light py-3 px-5">
    <div class="container">
      <p v-if="artist" class="has-text-grey-light">
        {{ artist.nickname }}<br />
        <nuxt-link :to="localePath({
          name: 'id-profile-imprint',
          params: { id: artist.urlAlias }
          })">{{ $t('imprint') }}
        </nuxt-link>
      </p>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    name: 'artistImprintLink',

    computed: {
      ...mapState(['artist'])
    }
  }
</script>
<style lang="scss">
.artist-imprint-line {
  width: 100%;
  background-color: #052D40;
  a {
    color: white;
    &.hover {
      text-decoration: underline;
    }
  }
}
</style>