import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const signup = () => import('./pages/portal/signup/index.vue').then(m => m.default || m)
const passwordRecovery = () => import('./pages/portal/password-recovery/index.vue').then(m => m.default || m)
const login = () => import('./pages/portal/login/index.vue').then(m => m.default || m)
const blog = () => import('./pages/portal/blog/index.vue').then(m => m.default || m)
const artistSignup = () => import('./pages/portal/artist-signup/_invite.vue').then(m => m.default || m)
const passwordRecoveryHash = () => import('./pages/portal/password-recovery/_pwhash.vue').then(m => m.default || m)
const signupHash = () => import('./pages/portal/signup/_signuphash.vue').then(m => m.default || m)
const blogDetail = () => import('./pages/portal/blog/_slug.vue').then(m => m.default || m)
const previewPost = () => import('./pages/preview/post/_id.vue').then(m => m.default || m)
const userDetail = () => import('./pages/user/_id/index.vue').then(m => m.default || m)
const portalStaticDyn = () => import('./pages/portal/_static.vue').then(m => m.default || m)
const portalStatic = () => import('./pages/portal/static.vue').then(m => m.default || m)

const home = () => import('./pages/index.vue').then(m => m.default || m)

const artist = () => import('./pages/_id.vue').then(m => m.default || m)
const artistProfile = () => import('./pages/_id/index.vue').then(m => m.default || m)
const artistFollower = () => import('./pages/_id/follower/_invite.vue').then(m => m.default || m)
const artistTos = () => import('./pages/_id/profile-tos/index.vue').then(m => m.default || m)
const artistPrivacy = () => import('./pages/_id/profile-privacy/index.vue').then(m => m.default || m)
const artistImprint = () => import('./pages/_id/profile-imprint/index.vue').then(m => m.default || m)
const artistCommunity = () => import('./pages/_id/community/index.vue').then(m => m.default || m)
const artistPosts = () => import('./pages/_id/posts/index.vue').then(m => m.default || m)
const artistTag = () => import('./pages/_id/posts/_tag.vue').then(m => m.default || m)
const artistRewards = () => import('./pages/_id/rewards/index.vue').then(m => m.default || m)
const artistRewardsPledge = () => import('./pages/_id/pledge/_rewardId.vue').then(m => m.default || m)
const artistProducts = () => import('./pages/_id/products/index.vue').then(m => m.default || m)
const artistPostDeeplink = () => import('./pages/_id/post/_slug/_postId.vue').then(m => m.default || m)
const artistNewsletterDeeplink = () => import('./pages/_id/newsletter/_newsletterId.vue').then(m => m.default || m)

const getScrollPosition = (target, offset = {}) => {
  return {
    selector: target.charAt(0) === '#' ? target : '#' + target,
    offset: { x: offset.x || 0, y: 58 + (offset.y || 0) }
  }
}

const scrollBehavior = function(to, from, savedPosition) {
  if (to.hash) {
    try {
      const validHashElement = document.querySelector(to.hash)
      if (validHashElement) return getScrollPosition(to.hash)
    } catch (error) {
      console.error('Invalid hash:', to.hash)
    }
  }

  // param
  if (to.params.scrollTo) {
    return getScrollPosition(to.params.scrollTo.target, to.params.scrollTo.offset)
  }

  if (to.meta.scroll && to.meta.scroll.target) {
    return getScrollPosition(to.meta.scroll.target)
  }

  return {
    x: 0,
    y: 0
  }
}

export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,
    routes: [
      {
        path: '/portal/signup',
        component: signup,
        name: 'portal-signup___en'
      },
      {
        path: '/portal/registrieren',
        component: signup,
        name: 'portal-signup___de'
      },
      {
        path: '/portal/password-recovery',
        component: passwordRecovery,
        name: 'portal-password-recovery___en'
      },
      {
        path: '/portal/passwort-wiederherstellen',
        component: passwordRecovery,
        name: 'portal-password-recovery___de'
      },
      {
        path: '/portal/login',
        component: login,
        name: 'portal-login___en'
      },
      {
        path: '/portal/einloggen',
        component: login,
        name: 'portal-login___de'
      },
      {
        path: '/portal/blog',
        component: blog,
        name: 'portal-blog___en'
      },
      {
        path: '/portal/blog',
        component: blog,
        name: 'portal-blog___de'
      },
      {
        path: '/portal/artist-signup/:invite?',
        component: artistSignup,
        name: 'portal-artist-signup-invite___en'
      },
      {
        path: '/portal/artist-signup/:invite?',
        component: artistSignup,
        name: 'portal-artist-signup-invite___de'
      },
      {
        path: '/portal/password-recovery/:pwhash?',
        component: passwordRecoveryHash,
        name: 'portal-password-recovery-pwhash___en'
      },
      {
        path: '/portal/password-recovery/:pwhash?',
        component: passwordRecoveryHash,
        name: 'portal-password-recovery-pwhash___de'
      },
      {
        path: '/portal/signup/:signuphash',
        component: signupHash,
        name: 'portal-signup-signuphash___en'
      },
      {
        path: '/portal/signup/:signuphash',
        component: signupHash,
        name: 'portal-signup-signuphash___de'
      },
      {
        path: '/portal/blog/:slug',
        component: blogDetail,
        name: 'portal-blog-slug___en'
      },
      {
        path: '/portal/blog/:slug',
        component: blogDetail,
        name: 'portal-blog-slug___de'
      },
      {
        path: '/preview/post/:id',
        component: previewPost,
        name: 'preview-post-id___en'
      },
      {
        path: '/preview/post/:id',
        component: previewPost,
        name: 'preview-post-id___de'
      },
      {
        path: '/user/:id/',
        component: userDetail,
        name: 'user-id___en'
      },
      {
        path: '/user/:id/',
        component: userDetail,
        name: 'user-id___de'
      },

      {
        path: '/portal/about',
        component: portalStatic,
        name: 'portal-static-about___en',
        meta: {
          contentId: 'cjki6kqdouugm0953si50i55j'
        }
      }, {
        path: '/portal/ueber',
        component: portalStatic,
        name: 'portal-static-about___de',
        meta: {
          contentId: 'cjki6l7d4uuho0953l13h4lmf'
        }
      },

      // used
      {
        path: '/portal/terms',
        component: portalStatic,
        name: 'portal-static-terms___en',
        meta: {
          contentId: 'cjl4pqes33jus0953btdpphku'
        }
      }, {
        path: '/portal/agb',
        component: portalStatic,
        name: 'portal-static-terms___de',
        meta: {
          contentId: 'cjl4ppz713jtt09533bdd2oda'
        }
      },

      // todo: where used?
      {
        path: '/portal/revocation',
        component: portalStatic,
        name: 'portal-static-revocation___en',
        meta: {
          contentId: 'cjrsvwwhiozf30a265tkxe15f'
        }
      }, {
        path: '/portal/widerrufsbelehrung',
        component: portalStatic,
        name: 'portal-static-revocation___de',
        meta: {
          contentId: 'cjl4pp73b3jsf0953e8bqzx8j'
        }
      },

      // used
      {
        path: '/portal/imprint',
        component: portalStatic,
        name: 'portal-static-imprint___en',
        meta: {
          contentId: 'cjl4pmimx3jmr095341b7e5kq'
        }
      }, {
        path: '/portal/impressum',
        component: portalStatic,
        name: 'portal-static-imprint___de',
        meta: {
          contentId: 'cjl4pn17n3jno0953syfeufz3'
        }
      },

      // used
      {
        path: '/portal/data-protection',
        component: portalStatic,
        name: 'portal-static-data-protection___en',
        meta: {
          contentId: 'cjl4pk7et3jhz09535fh95pxd'
        }
      }, {
        path: '/portal/datenschutz',
        component: portalStatic,
        name: 'portal-static-data-protection___de',
        meta: {
          contentId: 'cjl4po9183jqi0953hwqvvg49'
        }
      },

      // used
      {
        path: '/portal/policy',
        component: portalStatic,
        name: 'portal-static-policy___en',
        meta: {
          contentId: 'cjl4pqes33jus0953btdpphku'
        }
      }, {
        path: '/portal/nutzungsbedingungen',
        component: portalStatic,
        name: 'portal-static-policy___de',
        meta: {
          contentId: 'cjl4ppz713jtt09533bdd2oda'
        }
      },

      // used
      {
        path: '/portal/faq',
        component: portalStatic,
        name: 'portal-static-faq___en',
        meta: {
          contentId: 'cjki6jtd7uuel0953zdhtobzg'
        }
      }, {
        path: '/portal/haeufige-fragen',
        component: portalStatic,
        name: 'portal-static-faq___de',
        meta: {
          contentId: 'cjki6k66suuff095323s1kbax'
        }
      },

      // used
      {
        path: '/portal/payment-error',
        component: portalStatic,
        name: 'portal-static-payment-error___en',
        meta: {
          contentId: 'cjyrbvkn6duk90d53jwrsi4ri'
        }
      }, {
        path: '/portal/zahlungs-fehler',
        component: portalStatic,
        name: 'portal-static-payment-error___de',
        meta: {
          contentId: 'cjyrclc74lify09414y11p462'
        }
      },

      // used
      {
        path: '/portal/signup-as-artist',
        component: portalStatic,
        name: 'portal-static-signup-as-artist___en',
        meta: {
          contentId: 'cjri3rgla1am70a26eyoemq4m'
        }
      }, {
        path: '/portal/als-kuenstler-anmelden',
        component: portalStatic,
        name: 'portal-static-signup-as-artist___de',
        meta: {
          contentId: 'cjri3uzl21b2i0a26p2tiuj1w'
        }
      },

      // backward compatible
      // todo: remove after refactor
      {
        path: '/portal/:static',
        component: portalStaticDyn,
        name: 'portal-static___en'
      },
      {
        path: '/portal/:static',
        component: portalStaticDyn,
        name: 'portal-static___de'
      },

      {
        path: '/',
        component: home,
        name: 'index___en'
      },
      {
        path: '/',
        component: home,
        name: 'index___de'
      },
      {
        path: '/:id',
        component: artist,
        children: [
          {
            path: '/:id',
            component: artistProfile,
            name: 'id___en'
          },
          {
            path: 'follower/:invite?',
            component: artistFollower,
            name: 'id-profile-follower___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: 'profile-tos',
            component: artistTos,
            name: 'id-profile-tos___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: 'profile-privacy',
            component: artistPrivacy,
            name: 'id-profile-privacy___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/imprint',
            component: artistImprint,
            name: 'id-profile-imprint___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/community',
            component: artistCommunity,
            name: 'id-community___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/guestbook',
            component: artistCommunity,
            name: 'id-guestbook___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/posts',
            component: artistPosts,
            name: 'id-posts___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            },
            children: [
              {
                path: '/:id/posts/:tag',
                component: artistTag,
                name: 'id-posts-tag___en',
                meta: {
                  scroll: {
                    target: 'profile-navigation'
                  }
                }
              }
            ]
          },
          {
            path: '/:id/rewards',
            component: artistRewards,
            name: 'id-rewards___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/single-rewards',
            component: artistProducts,
            name: 'id-products___en',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: 'pledge/:rewardId',
            component: artistRewardsPledge,
            name: 'id-pledge-rewardId___en'
          },
          {
            path: 'post/:slug/:postId?/:itemType?/:itemId?',
            component: artistPostDeeplink,
            name: 'id-post-slug-postId___en'
          },
          {
            path: 'newsletter/:newsletterId',
            component: artistNewsletterDeeplink,
            name: 'id-newsletter-newsletterId___en'
          }
        ]
      },
      {
        path: '/:id',
        component: artist,
        children: [
          {
            path: '/:id',
            component: artistProfile,
            name: 'id___de'
          },
          {
            path: 'follower/:invite?',
            component: artistFollower,
            name: 'id-profile-follower___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: 'profile-tos',
            component: artistTos,
            name: 'id-profile-tos___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: 'profile-privacy',
            component: artistPrivacy,
            name: 'id-profile-privacy___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/impressum',
            component: artistImprint,
            name: 'id-profile-imprint___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/community',
            component: artistCommunity,
            name: 'id-community___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/guestbook',
            component: artistCommunity,
            name: 'id-guestbook___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/posts',
            component: artistPosts,
            name: 'id-posts___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            },
            children: [
              {
                path: '/:id/posts/:tag',
                component: artistTag,
                name: 'id-posts-tag___de',
                meta: {
                  scroll: {
                    target: 'profile-navigation'
                  }
                }
              }
            ]
          },
          {
            path: '/:id/rewards',
            component: artistRewards,
            name: 'id-rewards___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: '/:id/single-rewards',
            component: artistProducts,
            name: 'id-products___de',
            meta: {
              scroll: {
                target: 'profile-navigation'
              }
            }
          },
          {
            path: 'pledge/:rewardId',
            component: artistRewardsPledge,
            name: 'id-pledge-rewardId___de'
          },
          {
            path: 'post/:slug/:postId?/:itemType?/:itemId?',
            component: artistPostDeeplink,
            name: 'id-post-slug-postId___de'
          },
          {
            path: 'newsletter/:newsletterId',
            component: artistNewsletterDeeplink,
            name: 'id-newsletter-newsletterId___de'
          }
        ]
      }
    ],

    fallback: false
  })
}
