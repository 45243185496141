<template>
  <div>
    <div class="hero is-fullheight is-mainpage">
      <mainnav />
      <nuxt />
    </div>
    <mainfooter />
    <browserBanner />
  </div>
</template>

<script>
import mainnav from '~/components/mainnav'
import mainfooter from '~/components/mainfooter'
import browserBanner from '~/components/browser-banner'

export default {
  components: { mainnav, mainfooter, browserBanner },
  data: () => ({
    unscrollable: false
  }),
  methods: {
    toggleScrollable() {
      this.unscrollable = !this.unscrollable
      document.body.style.overflow = this.unscrollable ? 'hidden' : ''
      document.body.style.maxHeight = this.unscrollable ? '100vh' : ''
    },
    resetScrollable() {
      document.body.style.overflow = ''
      document.body.style.maxHeight = ''
    }
  },
  mounted() {
    this.resetScrollable()
    this.$root.$on('toggleBodyScrollable', this.toggleScrollable)
  },
  destroyed() {
    this.$root.$off('toggleBodyScrollable', this.toggleScrollable)
  }
}
</script>

<style media="screen">
.is-mainpage .navbar-end .navbar-item,
.is-mainpage .navbar-end .navbar-link {
  color: #fff;
}
</style>
