import axios from 'axios'
import { Message } from 'element-ui'

console.log('[API]', process.env.API_BASE_URL)

// let localeDomain = 'en'

/**
 * initialize HTTPClient
 */
const config = {
  validateStatus: function (status) {
    return status >= 200 && status < 500
  }
}
if (process.server) {
  config.baseURL = process.env.API_BASE_URL_SERVER
}
const HTTP = axios.create(config)

const showError = msg => {
  Message({
    message: msg,
    type: 'error',
    duration: 3500,
    showClose: true
  })
}

/**
 * error handling
 */
const onError = e => {
  console.error('API HTTP Error', e.toString())
  showError('An unknown error occured')
}

const onResponseError = response => {
  if (response.status === 401) {
    return showError(response.data.message || 'Authentication Error')
  }
  if (response.status === 403) {
    return showError(response.data.message || 'Authentication Error')
  }
  if (response.status >= 400) {
    return showError(response.data.message || 'An unknown error occured')
  }
}

/**
 * generic request
 */
const request = async (method, url, data, params, cookie) => {
  try {
    console.log('[API]', { method, url, data, params, cookie })
    // localeDomain = locale
    let headers = null
    if (cookie) headers = { Cookie: cookie }
    const response = await HTTP.request({ method, url, data, params, headers })
    if (response.status < 300) return response.data
    onResponseError(response)
  } catch (e) {
    onError(e)
  }
}

export default {

  methods: {
    /**
     * expose api[Post|Get|etc.]
     */
    apiPost(url, data, cookie) {
      return request('post', url, data, null, cookie)
    },

    apiPut(url, data) {
      return request('put', url, data)
    },

    apiGet(url, params, cookie) {
      return request('get', url, null, params, cookie)
    },

    apiDelete(url) {
      return request('delete', url)
    }
  }
}
