import Vue from 'vue'
import {
  Tabs,
  TabPane,
  Button
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Button)
