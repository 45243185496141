import Vue from 'vue'
import VeeValidate from 'vee-validate'

import Api from '~/mixins/api'

import attributesDe from '../i18n/validator/attributes/de.js'
import attributesEn from '../i18n/validator/attributes/en.js'
import messagesDe from '../i18n/validator/messages/de.js'
import messagesEn from '../i18n/validator/messages/en.js'

Vue.use(VeeValidate, {
  locale: 'en',
  dictionary: {
    en: {
      custom: messagesEn,
      attributes: attributesEn,
      name: 'en'
    },
    de: {
      custom: messagesDe,
      attributes: attributesDe,
      name: 'de'
    }
  }
})

const dictionary = VeeValidate.Validator.dictionary

const isUniqueEmail = async (value, data) => {
  let response
  if (data && data[0]) {
    response = await Api.methods.apiPost('/api/private/validate/email', {
      email: value,
      id: data[0]
    })
  } else {
    response = await Api.methods.apiPost('/api/private/validate/email', {
      email: value
    })
  }

  return {
    valid: response.valid,
    data: 'uniqueEmailAddress'
  }
}

VeeValidate.Validator.extend('uniqueEmailAddress', {
  validate: isUniqueEmail,
  getMessage: (field, params, data) => {
    return dictionary.getFieldMessage(VeeValidate.Validator.locale, 'uniqueEmailAddress', data)
  }
})

const isValidEmail = value => {
  // eslint-disable-next-line no-useless-escape
  const match = value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i)
  return {
    valid: match && match[0],
    data: 'validEmailAddress'
  }
}

VeeValidate.Validator.extend('validEmailAddress', {
  validate: isValidEmail,
  getMessage: (field, params, data) => {
    return dictionary.getFieldMessage(VeeValidate.Validator.locale, 'validEmailAddress', data)
  }
})

const isUniqueNick = async (value, data) => {
  let response
  if (data && data[0]) {
    response = await Api.methods.apiPost('/api/private/validate/nickname', {
      nickname: value,
      id: data[0]
    })
  } else {
    response = await Api.methods.apiPost('/api/private/validate/nickname', {
      nickname: value
    })
  }

  return {
    valid: response.valid,
    data: 'uniqueNickname'
  }
}

VeeValidate.Validator.extend('uniqueNickname', {
  validate: isUniqueNick,
  getMessage: (field, params, data) => {
    return dictionary.getFieldMessage(VeeValidate.Validator.locale, 'uniqueNickname', data)
  }
})

const isPasswordStrong = async value => {
  const response = await Api.methods.apiPost('/api/private/validate/password', {
    password: value
  })
  return {
    valid: response.valid,
    data: {
      message: response.feedback.warning || 'Password is too weak.',
      feedback: response.feedback,
      score: response.score
    }
  }
}

VeeValidate.Validator.extend('passwordStrength', {
  validate: isPasswordStrong,
  getMessage: (field, params, data) => {
    return {
      message: data.message,
      feedback: data.feedback,
      score: data.score
    }
  }
})
