export default {
  email: {
    validEmailAddress: 'Diese E-Mail-Adresse ist ungültig!',
    uniqueEmailAddress: 'Diese E-Mail-Adresse ist schon vergeben.',
    required: 'Eine E-Mail-Adresse ist erforderlich.'
  },
  nickname: {
    uniqueNickname: 'Dieser Name ist bereits vergeben.',
    required: 'Ein Benutzername ist erforderlich.'
  },
  password: {
    passwordStrength: 'Das Passwort ist noch nicht stark genug.',
    required: 'Ein Passwort ist erforderlich.'
  },
  bankDetailsOwner: {
    required: 'Der Name des Kontoinhabers ist erforderlich.'
  },
  iban: {
    required: 'Die IBAN ist für Auszahlungen erforderlich.'
  },
  salutation: {
    required: 'Bitte die gewünschte Anrede wählen.'
  },
  payoutFirstName: {
    required: 'Die Angabe des Vornamens ist erforderlich.'
  },
  payoutLastName: {
    required: 'Die Angabe des Nachnamens ist erforderlich.'
  },
  payoutAddressStreet: {
    required: 'Die Angabe der Straße ist erforderlich.'
  },
  payoutAddressPostcode: {
    required: 'Die Angabe der Postleitzahl ist erforderlich.'
  },
  payoutAddressCity: {
    required: 'Die Angabe der Stadt ist erforderlich.'
  },
  country: {
    required: 'Die Angabe des Staates ist erforderlich.'
  },
  passwordMeter: {
    suggestion: {
      'No need for symbols, digits, or uppercase letters': 'Keine Notwendigkeit von Sonderzeichen, Zahlen oder Großbuchstaben',
      'Use a few words, avoid common phrases': 'Verwende ein paar Wörter, vermeide allgemeine Redewendungen',
      'Add another word or two. Uncommon words are better.': 'Füge noch ein oder zwei weitere Wörter hinzu. Ungewöhnliche Wörter sind besser.',
      'Avoid repeated words and characters': 'Vermeide die Wiederholung von Wörtern und Buchstaben.',
      'Use a longer keyboard pattern with more turns': 'Verwende längere Muster mit mehr Unregelmäßigkeiten',
      'Avoid dates and years that are associated with you': 'Vermeide Datums- und Jahresangaben, die mit Dir in Verbindung stehen.',
      'Avoid recent years': 'Vermeide vergangene Jahre.',
      'Avoid years that are associated with you': 'Vermeide Jahre, die mit Dir in Verbindung stehen.',
      "Capitalization doesn't help very much": 'Großschreibung hilft nicht besonders viel.',
      'All-uppercase is almost as easy to guess as all-lowercase': 'Nur Großschreibung ist fast so leicht zu erraten wie nur Kleinschreibung.',
      "Reversed words aren't much harder to guess": 'Rückwärts geschriebene Wörter sind nicht viel schwerer zu erraten.',
      "Predictable substitutions like '@' instead of 'a' don't help very much": "Vorhersagbare Ersetzungen wie '@' statt 'a' helfen nicht viel."
    },
    warning: {
      'Straight rows of keys are easy to guess': 'Durchgehende Tastaturfolgen sind leicht zu erraten.',
      'Short keyboard patterns are easy to guess': 'Short keyboard patterns are easy to guess.',
      'Repeats like "aaa" are easy to guess': 'Wiederholungen wie "aaa" sind leicht zu erraten.',
      'Sequences like abc or 6543 are easy to guess': 'Abfolgen wie "abc" oder "6543" sind leicht zu erraten.',
      'Recent years are easy to guess': 'Vergangene Jahre sind leicht zu erraten.',
      'Dates are often easy to guess': 'Datumsangaben sind oft leicht zu erraten',
      'This is a top-10 common password': 'Dieses Passwort ist in der Top 10 der am weitesten verbreiteten Passwörter.',
      'This is a top-100 common password': 'Dieses Passwort ist in der Top 100 der am weitesten verbreiteten Passwörter.',
      'This is a very common password': 'Dies ist ein ziemlich verbreitetes Passwort.',
      'A word by itself is easy to guess': 'Ein Wort alleine ist einfach zu erraten.',
      'Names and surnames by themselves are easy to guess': 'Namen und Nachnamen sind leicht zu erraten, wenn sie alleine stehen.',
      'Common names and surnames are easy to guess': 'Bekannte Namen und Nachnamen sind einfach zu erraten.'

    }
  }
}
