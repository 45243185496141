<template>
  <label class="color-picker-container">
    <input type="color" class="color-picker" :value="value"
      @input="$emit('color-changed', { colorName, value: $event.target.value })">
    <span class="label-text">{{ label }}</span>
  </label>
</template>

<style lang="scss">
.color-picker-container {
  display: flex;
  align-items: center;
}

.color-picker {
  margin-right: 8px;
}
</style>

<script>
export default {
  name: 'ColorPicker',
  props: {
    label: {
      type: String,
      default: 'Choose a color'
    },
    colorName: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: '#000000'
    }
  }
}
</script>
