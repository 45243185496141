<template>
  <div>
    <md-field :class="addedFieldClasses">
      <label>{{ label }}<span v-if="isRequired"> *</span></label>
      <md-input
        v-model="initialValue"
        :type="type"
        :name="myName"
        @input="updateModel"
        :class="{ required: isRequired, 'is-danger': errors.has(myIdentifier) }"
        :disabled="disabled"
        v-validate="validate"
        :data-vv-scope="myScope"
        data-vv-delay="300"
      ></md-input>
    </md-field>
    <input-error :name="myIdentifier" />
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: String,
    label: String,
    validate: String,
    type: String,
    scope: String,
    disabled: Boolean,
    additionalFieldClasses: {
      type: Array,
      default: () => []
    }
  },
  inject: ['$validator'],
  data: () => ({
    initialValue: ''
  }),
  computed: {
    myIdentifier() {
      if (!this.myScope) {
        return this.myName
      }
      return `${this.myScope}.${this.myName}`
    },
    myName() {
      return this.name || this.label
    },
    myScope() {
      return this.scope || null
    },
    isRequired() {
      return this.validate && this.validate.indexOf('required') > -1
    },
    addedFieldClasses() {
      return this.additionalFieldClasses.reduce((acc, val) => {
        acc[val] = true
        return acc
      }, {})
    }
  },
  methods: {
    updateModel(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss">
@import 'assets/sass/_variables';
.md-input {
  border-bottom: 1px solid rgba(0,0,0,.5) !important;
  &:focus {
    border-bottom: 2px solid var(--primary-color) !important;
  }
}
</style>