<template>
  <div id="login-pane">
    <div class="columns">
      <div
        class="column mdc-theme--light"
        :class="{
          'is-6 is-offset-3': !params.isWizard,
          'is-10 is-offset-1': params.isWizard
        }">
        <div
          v-show="showLogin">
          <h3 class="title is-3 has-text-centered mb-0">{{$t('login')}}</h3>
          <form
            novalidate
            method="post"
            @submit.prevent="submitCredentials">
            <input-text v-model="email"
              id="email"
              :label="$t('email')"
              name="email"
              scope="login"
              validate="required|validEmailAddress"/>

            <input-text v-model="password"
              type="password"
              scope="login"
              :label="$t('password')"
              name="password"
              validate="required"
              id="password"
              :additionalFieldClasses="['mb-2']"/>

            <div class="password-forgotten has-text-right">
              <div v-if="params.isWizard">
                <span
                  :disabled="errors.has('login.email')"
                  v-if="!showRecovery && !showRegistration"
                  @click.prevent="activateRecovery(true)"
                  class="is-clickable is-small is-fake-anchor"
                  tabindex="0">
                  {{$t('forgotPassword')}}
                </span>
              </div>
              <nuxt-link v-else
                :to="localePath({ name: 'portal-password-recovery' })"
                class="is-fullwidth is-small"
                id="recovery">{{$t('forgotPassword')}}</nuxt-link>
            </div>

            <div class="control mt-50">
              <button type="submit"
                :disabled="errors.any('login')"
                class="button is-primary is-outlined is-fullwidth is-medium"
                id="submit-login">{{$t('submit')}} login</button>
              <input type="submit"
                style="position: absolute; left: -9999px">
            </div>
          </form>
        </div>
        <div
          v-show="showRecovery">
          <h3 class="title is-3 has-text-centered">{{$t('resetTitle')}}</h3>
          <form
            v-if="!recoverySent"
            novalidate
            method="post"
            @submit.prevent="submitRecovery">
            <p class="has-text-grey">{{$t('enterEmailForNewPassword')}}</p>

            <input-text v-model="email"
              id="email"
              :label="$t('email')"
              name="email"
              scope="recovery"
              validate="required|validEmailAddress"/>

            <div class="control mt-50">
              <div class="button-column-group">
                <button type="submit"
                  :disabled="errors.any('recovery')"
                  class="button is-primary is-outlined is-fullwidth is-medium"
                  id="submit-recovery">{{$t('submit')}}</button>
                <button
                  @click.prevent="activateRecovery(false)"
                  class="button mt-20 is-outlined is-fullwidth is-medium is-in-column">
                  {{$t('cancel')}}
                </button>
              </div>

              <input type="submit"
                style="position: absolute; left: -9999px">
            </div>
          </form>
          <div v-else>
            <p class="has-text-grey" v-html="$t('recoveryLinkSent', {email: email})"></p>
            <button
              @click.prevent="resendRecovery"
              class="button is-primary mt-20 is-outlined is-fullwidth is-medium">
              {{$t('resendRecoveryLink')}}
            </button>
          </div>
        </div>

        <div v-show="showRegistration">
          <h3 class="title is-3 has-text-centered">{{$t('signup')}}</h3>
          <div
            v-if="!registrationSent"
            class="columns mt-25 mb-25">
            <div class="column is-12 is-offset-0 mdc-theme--light">
              <h3 class="title is-5 is-primary-color is-bold is-italic">{{$t('registration.welcome.heading')}}</h3>
              <p>{{$t('registration.welcome.text')}}</p>
              <dl id="registration-description-list" class="mt-10 mb-15">
                <dt>{{$t('registration.welcome.list.first.title')}}</dt><dd>{{$t('registration.welcome.list.first.text')}}</dd>
                <dt>{{$t('registration.welcome.list.second.title')}}</dt><dd>{{$t('registration.welcome.list.second.text')}}</dd>
                <dt>{{$t('registration.welcome.list.third.title')}}</dt><dd>{{$t('registration.welcome.list.third.text')}}</dd>
              </dl>
              <p>{{$t('registration.welcome.artistSignup.text')}}, <a href="/#apply">{{$t('registration.welcome.artistSignup.signupAnchor')}}</a>.</p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12 is-offset-0 mdc-theme--light">
              <div v-if="registrationSent">
                <h4 class="has-text-centered">{{$t('thankYou')}}</h4>
                <h4 class="has-text-centered">{{$t('registrationSent')}}</h4>
              </div>
              <form
                v-if="!registrationSent"
                novalidate
                method="post"
                @submit.prevent="submitRegistration">
                <div class="columns is-gapless is-formfields">
                  <div class="column">
                    <input-text v-model="email"
                      id="email"
                      :label="$t('email')"
                      name="email"
                      scope="registration"
                      validate="required|validEmailAddress|uniqueEmailAddress"
                      class="is-medium" />
                  </div>
                </div>
                <div class="columns is-gapless is-formfields">
                  <div class="column">
                    <input-text v-model="nickname"
                      id="nickname"
                      name="nickname"
                      :label="$t('nickName')"
                      scope="registration"
                      autocomplete="off"
                      validate="required|uniqueNickname"
                      class="is-medium" />
                  </div>
                </div>
                <div class="columns is-gapless is-formfields">
                  <div class="column">
                    <input-text v-model="password"
                      id="password"
                      type="password"
                      name="password"
                      :label="$t('password')"
                      scope="registration"
                      validate="required|passwordStrength"
                      autocomplete="off"
                      class="is-medium" />
                    <passwordMeter
                      id="passwordMeter"
                      :value="password" />
                  </div>
                </div>
                <div class="columns is-gapless is-formfields">
                  <div class="column">
                    <div class="field">
                      {{$t('registration.fan.policyAcceptance')}} <nuxt-link :to="localePath({ name: 'portal-static-terms' })" target="_blank">{{$t('registration.terms')}}</nuxt-link>.
                    </div>
                  </div>
                </div>
                <div class="columns is-gapless">
                  <div class="column">
                    (*) {{$t('mandatory')}}
                  </div>
                </div>
                <div class="control mt-50">
                  <div class="button-column-group">
                    <button
                      id="submit-registration"
                      type="submit"
                      :disabled="errors.any('registration')"
                      class="button is-primary is-outlined is-fullwidth is-medium">{{$t('createAccount')}}</button>
                    <button
                        @click.prevent="activateRegistration(false)"
                        class="button mt-20 is-outlined is-fullwidth is-medium is-in-column">
                        {{$t('cancel')}}
                    </button>
                  </div>
                  <input type="submit"
                    style="position: absolute; left: -9999px">
                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-if="params.isWizard">
          <div>
            <button
              v-if="!showRegistration && !showRecovery"
              @click.prevent="activateRegistration(true)"
              class="button is-secondary mt-20 is-outlined is-fullwidth is-medium">
              {{$t('signup')}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div
        class="column is-10 is-offset-1">
        <p class="has-text-centered" v-html="$t('problemLogin')"></p>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import passwordMeter from '~/components/password-meter'

export default {
  name: 'loginComponent',
  serverCacheKey: () => 'login-component',
  inject: ['$validator'],
  props: ['from', 'params'],
  components: { passwordMeter },
  data: () => ({
    email: '',
    password: '',
    nickname: '',
    hasError: false,
    showLogin: true,
    showRecovery: false,
    showRegistration: false,
    recoverySent: false,
    registrationSent: false
  }),

  mounted() {
    this.$validator.localize(this.$i18n.locale)
  },

  methods: {
    ...mapActions(['login', 'storeReturnRouteObject', 'storeRedirectPath']),
    async submitCredentials() {
      const valid = await this.$validator.validate('login.*')
      if (valid) {
        const data = await this.apiPost('/api/private/authenticate', this.$data)
        if (data) {
          data.from = this.from

          if (this.$route.query.redirect) {
            this.storeRedirectPath(this.$route.query.redirect)
          }

          if (this.params && this.params.returnRouteObject) {
            this.storeReturnRouteObject(this.params.returnRouteObject)
          }
          this.login(data)
          this.$root.$emit('refreshAPI')
          this.$emit('logged')
        }
      }
    },
    activateRecovery(active) {
      if (!active) {
        this.recoverySent = false
        this.$emit('viewChanged', this.$t('login'))
      } else {
        this.$emit('viewChanged', this.$t('resetTitle'))
      }
      this.showLogin = !active
      this.showRecovery = active
      this.recoverySent = !active
    },
    activateRegistration(active) {
      if (!active) {
        this.registrationsSent = false
        this.$emit('viewChanged', this.$t('login'))
      } else {
        this.$emit('viewChanged', this.$t('signup'))
      }
      this.showRegistration = active
      this.showLogin = !active
      this.recoverySent = !active
    },
    async submitRecovery() {
      const valid = await this.$validator.validate('recovery.*')
      if (valid) this.resendRecovery()
    },
    async resendRecovery() {
      const data = await this.apiPost('/api/private/password-recovery', {
        email: this.email,
        loginRedirectRoute: this.getLoginRedirectRoute()
      })
      if (data.ok) {
        this.recoverySent = true
      }
      if (data.error) {
        this.errorMessage('Unable to request password reset for that address.')
      }
    },
    async submitRegistration() {
      const valid = await this.$validator.validate('registration.*')
      if (valid) {
        const result = await this.apiPost('/api/private/register', {
          email: this.email,
          password: this.password,
          nickname: this.nickname,
          loginRedirectRoute: this.getLoginRedirectRoute()
        })
        if (result) {
          this.registrationSent = true
        }
      }
    },
    getLoginRedirectRoute() {
      return {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query
      }
    }
  }
}
</script>
<style lang="scss">
@import 'assets/sass/_variables';

#login-pane {
  h3 {
    color: #000000 !important;
    font-weight: 300 !important;
  }
  button {
    line-height: 1;
    padding: 10px 15px;
  }

}
.button-column-group {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  button.is-in-column {
    margin-left: 0rem !important;
  }
}

.is-fake-anchor {
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
    color: hsl(0, 0%, 21%);
  }
}
</style>
