import { Validator } from 'vee-validate'

export default {
  methods: {
    localize(localeName) {
      // load the locale file asynchronously, then localize the validator with it.
      import(`vee-validate/dist/locale/${localeName}`).then(locale => {
        Validator.localize(localeName, locale)
      })
    }
  }
}
