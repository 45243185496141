var render = function render(){var _vm=this,_c=_vm._self._c;return _c('no-ssr',[(!this.isAuthenticated)?_c('div',[_c('div',{staticClass:"social-button-container mt-10"},[_c('div',{staticClass:"social-button"},[(!_vm.showRegistrationForm)?_c('vue-facebook-login',{attrs:{"tabindex":"1","app-id":"235969604174559","login-options":{ scope: 'email' }},on:{"login":_vm.handleLogin,"sdk-init":_vm.handleFacebookSdkInit},model:{value:(_vm.fbModel),callback:function ($$v) {_vm.fbModel=$$v},expression:"fbModel"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"social-button"},[(!_vm.showRegistrationForm)?_c('vue-google-login',{attrs:{"tabindex":"2","params":{
          client_id: '79115176131-a6rbi8sl5hg30sm6s6rdtkjht0adbfh7.apps.googleusercontent.com',
        },"renderParams":{
            width: 207,
            height: 36,
            longtitle: true
          },"login-options":{ scope: 'email' },"onSuccess":_vm.handleLogin,"onFailure":_vm.handleGoogleFailure}}):_vm._e()],1)]),_vm._v(" "),(_vm.showRegistrationForm)?_c('socialRegistrationForm',{attrs:{"modalRegistration":_vm.modalRegistration,"user":_vm.user},on:{"valid":_vm.submitRegistration,"cancelled":_vm.cancelRegistration}}):_vm._e(),_vm._v(" "),(_vm.divider && !_vm.showRegistrationForm)?_c('div',{staticClass:"is-divider mb-20",attrs:{"data-content":_vm.$t('or')}}):_vm._e()],1):_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }