export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"numberFormats":{"en-US":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}},"de-DE":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en-US.js","domain":"getnext.to"},{"code":"de","iso":"de-De","file":"de-DE.js","domain":"de.getnext.to"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "i18n/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {"index":{"en":"/","de":"/"},"_id/index":{"en":"/:id","de":"/:id"},"_id/community/index":{"en":"/:id/community","de":"/:id/community"},"_id/post/_slug":{"en":"/:id/post/:slug","de":"/:id/post/:slug"},"_id/posts/index":{"en":"/:id/posts","de":"/:id/posts"},"_id/profile-imprint/index":{"en":"/:id/imprint","de":"/:id/impressum"},"_id/profile-terms/index":{"en":"/:id/terms","de":"/:id/agb"},"_id/rewards/index":{"en":"/:id/rewards","de":"/:id/rewards"},"user/_id/index":{"en":"/user/:id/","de":"/user/:id/"},"hello":{"en":"/hello","de":"/hallo"},"portal/artist-signup/_hash":{"en":"/portal/artist-signup","de":"/portal/kuenstler-registrierung"},"portal/blog/index":{"en":"/portal/blog","de":"/portal/blog"},"portal/blog/_slug":{"en":"/portal/blog/:slug","de":"/portal/blog/:slug"},"portal/login/index":{"en":"/portal/login","de":"/portal/einloggen"},"portal/password-recovery/index":{"en":"/portal/password-recovery","de":"/portal/passwort-wiederherstellen"},"portal/password-recovery/_hash":{"en":"/portal/password-recovery/:hash","de":"/portal/passwort-wiederherstellen/:hash"},"portal/signup/index":{"en":"/portal/signup","de":"/portal/registrieren"},"portal/_static":{"en":"/portal/:static","de":"/portal/:static"},"preview/post/_id":{"en":"/preview/post/:id","de":"/preview/post/:id"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  silentTranslationWarn: true,
  seo: true,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US.js","domain":"getnext.to"},{"code":"de","iso":"de-De","file":"de-DE.js","domain":"de.getnext.to"}],
  localeCodes: ["en","de"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "i",
  17: "1",
  18: "8",
  19: "n",
  20: "/",
  21: "e",
  22: "n",
  23: "-",
  24: "U",
  25: "S",
  26: ".",
  27: "j",
  28: "s",
  29: "\"",
  30: ",",
  31: "\"",
  32: "d",
  33: "e",
  34: "-",
  35: "D",
  36: "E",
  37: ".",
  38: "j",
  39: "s",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "i",
  47: "1",
  48: "8",
  49: "n",
  50: "/",
  51: "d",
  52: "e",
  53: "-",
  54: "D",
  55: "E",
  56: ".",
  57: "j",
  58: "s",
  59: "\"",
  60: "}",
}

export const localeMessages = {
  'en-US.js': () => import('../../i18n/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'de-DE.js': () => import('../../i18n/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
}
