<template>
  <div>
    <transition name="fade">
      <div v-show="profileLoading" class="profile"
        :style="{ backgroundColor: defaultBackgroundColor, position: 'fixed', width: '100%', height: '100vh' }">
      </div>
    </transition>
    <transition name="fade">
      <div v-show="!profileLoading" id="dynamic-color-scope" class="profile" :style="userColorStyles">
        <div>
          <mainnav />
          <nuxt />
        </div>
        <artistImprintLink v-show="isMobileView" />
        <mainfooter />
        <browserBanner />
        <no-ssr>
          <colorMenu v-if="artist.artist && myProfile" />
          <FlashMessage :position="'right bottom'"></FlashMessage>
        </no-ssr>
      </div>
    </transition>
  </div>
</template>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<script>
import { mapState } from 'vuex'

import mainnav from '~/components/mainnav'
import mainfooter from '~/components/mainfooter'
import browserBanner from '~/components/browser-banner'
import colorMenu from '~/components/color-menu'
import artistImprintLink from '~/components/artist-imprint-link'
import profileColorsMixin from '~/mixins/profileColors'

export default {
  components: { mainnav, mainfooter, browserBanner, colorMenu, artistImprintLink },
  head: () => ({
    bodyAttrs: {
      class: 'isProfile'
    }
  }),
  mixins: [profileColorsMixin],
  data: () => ({
    unscrollable: false
  }),
  computed: {
    ...mapState(['artist', 'user', 'isAuthenticated', 'profileLoading']),
    myProfile() {
      if (this.isAuthenticated) {
        return this.artist.nickname === this.user.nickname
      }
      return false
    },
    defaultBackgroundColor() {
      if (process.client && window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return '#121212' // dark mode
      } else {
        return '#eeeeee' // light mode
      }
    }
  },
  methods: {
    scrollToTarget(target) {
      if (process.client && window) {
        window.history.scrollRestoration = 'manual'
      }

      const hashTarget = target && target.startsWith('#') ? target : `#${target}`
      if (!hashTarget) return

      try {
        const hashTargetElement = document.querySelector(hashTarget)
        this.$scrollTo(hashTargetElement, {
          duration: 800,
          force: true,
          cancelable: false
        })
      } catch (error) {
        console.error('Invalid hash:', hashTarget)
      }
    },
    toggleScrollable() {
      this.unscrollable = !this.unscrollable
      document.body.style.overflow = this.unscrollable ? 'hidden' : ''
      document.body.style.maxHeight = this.unscrollable ? '100vh' : ''
    },
    resetScrollable() {
      document.body.style.overflow = ''
      document.body.style.maxHeight = ''
    },
    handleFlash({ type, state = 'success', flashConfig = {}, payload = {} }) {
      if (!type) return
      this.flashMessage[state]({
        title: this.$t(`${type}.${state}.title`, { ...payload }),
        message: this.$t(`${type}.${state}.message`, { ...payload })
      }, { ...flashConfig })
    }
  },
  mounted() {
    this.resetScrollable()
    this.$root.$on('toggleBodyScrollable', this.toggleScrollable)
    this.$root.$on('flash', this.handleFlash)

    this.$nextTick(() => {
      if (this.$route.hash) {
        return this.scrollToTarget(this.$route.hash)
      }
      if (this.$route.params.scrollTo && this.$route.params.scrollTo.target) {
        return this.scrollToTarget(this.$route.params.scrollTo.target)
      }
      if (this.$route.meta.scroll && this.$route.meta.scroll.target) {
        return this.scrollToTarget(this.$route.meta.scroll.target)
      }
    })
  },
  destroyed() {
    this.$root.$off('toggleBodyScrollable', this.toggleScrollable)
    this.$root.$off('flash', this.handleFlash)
  }
}
</script>
