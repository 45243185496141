import Plausible from 'plausible-tracker'

export default ({ app, store }, inject) => {
  const apiHost = 'https://plausible.getnext.to'
  let plausibleSegmented = null
  let cleanupSegmented = null
  let plausibleGlobal = null
  let cleanupGlobal = null
  inject('plausibleSegmented', {
    load(domain) {
      plausibleSegmented = Plausible({
        apiHost,
        domain,
        trackLocalhost: true
      })
      cleanupSegmented = plausibleSegmented.enableAutoPageviews()
    },
    clean() {
      if (cleanupSegmented) cleanupSegmented()
      plausibleSegmented = null
    }
  })
  inject('plausibleGlobal', {
    load(domain) {
      plausibleGlobal = Plausible({
        apiHost,
        domain,
        trackLocalhost: true
      })
      cleanupGlobal = plausibleGlobal.enableAutoPageviews()
    },
    clean() {
      if (cleanupGlobal) cleanupGlobal()
      plausibleGlobal = null
    }
  })
}
