import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// Replace this with your project's endpoint
const GRAPHCMS_API = 'https://api-eu-central-1.graphcms.com/v2/cjkhzdd6110pw01gm95vcofcy/master'

export default () => ({
  link: new HttpLink({ uri: GRAPHCMS_API }),
  cache: new InMemoryCache()
})
