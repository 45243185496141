import { DateTime, Interval } from 'luxon'
import { Message } from 'element-ui'
import clone from 'rfdc/default'

const conversions = {
  'ae': 'ä|æ|ǽ',
  'oe': 'ö|œ',
  'ue': 'ü',
  'a': 'à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª',
  'c': 'ç|ć|ĉ|ċ|č',
  'd': 'ð|ď|đ',
  'e': 'è|é|ê|ë|ē|ĕ|ė|ę|ě',
  'g': 'ĝ|ğ|ġ|ģ',
  'h': 'ĥ|ħ',
  'i': 'ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı',
  'j': 'ĵ',
  'k': 'ķ',
  'l': 'ĺ|ļ|ľ|ŀ|ł',
  'n': 'ñ|ń|ņ|ň|ŉ',
  'o': 'ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º',
  'r': 'ŕ|ŗ|ř',
  's': 'ś|ŝ|ş|š|ſ',
  't': 'ţ|ť|ŧ',
  'u': 'ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ',
  'y': 'ý|ÿ|ŷ',
  'w': 'ŵ',
  'z': 'ź|ż|ž',
  'ss': 'ß',
  'ij': 'ĳ',
  'f': 'ƒ'
}

const createMonthlyIntervals = (months) => {
  const intervals = []
  let currentInterval = {}
  for (let i = 0; i < months.length - 1; i++) {
    const currentMonth = months[i]
    const nextMonth = months[i + 1]

    if (!currentInterval.start) {
      currentInterval.start = currentMonth.start
    }

    if (!nextMonth || currentMonth.end !== nextMonth.start) {
      currentInterval.end = currentMonth.end
      intervals.push(currentInterval)
      currentInterval = {}
    }
  }
  return intervals
}

const mimeTypes = {
  jpg: 'jpg',
  jpeg: 'jpg',
  gif: 'gif',
  png: 'png'
}
const fakeMimeType = (extension) => {
  return `image/${mimeTypes[extension]}`
}

export default {
  computed: {
    isMobileView() {
      return ['sm', 'md'].includes(this.$mq)
    }
  },

  methods: {
    fullLocale(locale) {
      switch (locale) {
        case 'de': {
          return 'de-DE'
        }
        default: return 'en-US'
      }
    },
    formatDate(date) {
      let input = DateTime.fromISO(date)
      return date && input.isValid ? input.toFormat('yyyy-LL-dd, H:mm') : ''
    },
    localeDate(date, locale) {
      let input = DateTime.fromISO(date)
      const l = locale || this.$i18n.locale
      const suffix = l === 'de' ? ' Uhr' : ''
      return (date && input.isValid)
        ? input
          .setLocale(this.fullLocale(l))
          .toLocaleString(DateTime.DATETIME_SHORT)
          .concat(suffix)
        : ''
    },
    formatDateShort(date) {
      let input = DateTime.fromISO(date)
      return date && input.isValid ? input.toFormat('yyyy-LL-dd') : ''
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €`
    },
    errorMessage(msg) {
      Message({
        message: msg,
        type: 'error',
        duration: 3500,
        showClose: true
      })
    },
    centToEuro(value, format) {
      const euro = (value / 100)
      return (format) ? this.formatPrice(euro) : euro
    },
    centToCurrency(value, factor, currency) {
      const converted = Math.round(value * factor) / 100
      return this.$n(converted, {
        key: 'currency',
        locale: this.fullLocale(this.$i18n.locale),
        currency: currency
      })
    },
    euroToCent(value) {
      return (value * 100)
    },

    monthsByYearSince(d, locale) {
      const getMonthNames = (locale = 'en', format = 'long') => {
        const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' })
        const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
          const mm = month < 10 ? `0${month}` : month
          return new Date(`2017-${mm}-01T00:00:00+00:00`)
        })
        return months.map(date => formatter.format(date))
      }
      const monthNames = getMonthNames(locale)

      const months = function* (interval) {
        let cursor = {
          start: interval.start.startOf('day'),
          end: interval.start.plus({ months: 1 }).startOf('day')
        }
        while (cursor.start < interval.end) {
          yield cursor
          cursor = {
            start: cursor.start.plus({ months: 1 }),
            end: cursor.end.plus({ months: 1 })
          }
        }
      }
      const byYear = (months) => months.reduce((acc, val) => {
        const data = {
          _id: `${monthNames[val.start.month - 1]}-${val.start.year}`,
          name: monthNames[val.start.month - 1],
          year: val.start.year,
          month: val.start.month - 1,
          start: val.start.toISO().substring(0, 7),
          end: val.end.toISO().substring(0, 7)
        }
        const findIdx = acc.findIndex((o) => o.name === `${val.start.year}`)
        if (findIdx < 0) {
          const group = {
            name: `${val.start.year}`,
            list: [data]
          }
          if (val.start.year === 2021) {
            group.selected = true
          }
          acc.push(group)
          return acc
        }

        acc[findIdx].list.push(data)
        return acc
      }, [])

      const start = DateTime.fromISO(d)
      const end = DateTime.fromMillis(Date.now()).endOf('month')
      const i = Interval.fromDateTimes(start, end)
      return byYear(Array.from(months(i)))
    },

    filterPosts(postFilter, artistPosts) {
      let posts = artistPosts

      if (postFilter.public) {
        posts = posts.filter((post) => post.mediaTier === 'public')
      } else if (postFilter.limitToFollowers) {
        posts = posts.filter((post) => post.limitToFollowers)
      } else if (
        (postFilter.product && postFilter.product.length) ||
        (postFilter.reward && postFilter.reward.length)
      ) {
        posts = posts.filter((post) =>
          postFilter.product.some(el => post.limitToProduct.includes(el)) ||
          postFilter.reward.some(el => post.limitToReward.includes(el))
        )
      }

      if (postFilter.tags && postFilter.tags.length) {
        const ids = postFilter.tags.map((t) => t.id)
        posts = posts.filter(
          (post) => post.tags.some((tag) => ids.includes(tag.id))
        )
      }

      if (postFilter.months && postFilter.months.length) {
        const months = postFilter.months.concat().sort((a, b) => {
          if (a.start === b.start) return 0
          return a.start < b.start ? -1 : 1
        })
        months.push(null)

        const intervals = createMonthlyIntervals(months)

        posts = posts.filter(
          (post) => intervals.some(
            (month) => month.start <= post.publishDateTime && post.publishDateTime < month.end
          )
        )
      }

      if (postFilter.contents && postFilter.contents.length) {
        posts = posts.filter((post) => {
          if (post.stats) {
            for (const type of postFilter.contents) {
              switch (type._id) {
                case 'image':
                  if (post.stats.images) return true
                  break
                case 'audio':
                  if (post.stats.audios) return true
                  break
                case 'video':
                  if (post.stats.videos) return true
                  break
                case 'pdf':
                  if (post.stats.pdfs) return true
                  break
                case 'zip':
                  if (post.stats.zips) return true
                  break
              }
            }
          }

          const media = [
            ...(post.media || []),
            ...(post.inlineMedia || [])
              .map((m) => {
                m.mimeType = fakeMimeType(m.extension)
                return m
              })
          ]
          for (const type of postFilter.contents) {
            switch (type._id) {
              case 'image':
                if (this.getImages(media).length > 0) return true
                break
              case 'audio':
                if (this.getAudios(media).length > 0) return true
                break
              case 'video':
                if (this.getVideos(media).length > 0) return true
                break
              case 'pdf':
                if (this.getPDFs(media).length > 0) return true
                break
              case 'zip':
                if (this.getZips(media).length > 0) return true
                break
            }
          }
          return false
        })
      }

      return posts
    },
    getImages(collection) {
      return collection.filter(item => item.mimeType.match(/^image\/(jpg|jpeg|gif|png|webp)$/))
    },
    getAudios(collection) {
      return collection.filter(item => item.mimeType.match('audio/*'))
    },
    getVideos(collection) {
      return collection.filter(item => item.mimeType.match('video/*') || item.mimeType.match('application/x-mpegurl'))
    },
    getPDFs(collection) {
      return collection.filter(item => item.mimeType.match('application/pdf'))
    },
    getZips(collection) {
      return collection.filter(item => item.mimeType.match('application/zip'))
    },
    urlSlug(str) {
      if (!str) {
        return 'no-title'
      }
      let formatted = str.toLowerCase()
      for (const i in conversions) {
        formatted = formatted.replace(new RegExp(conversions[i], 'g'), i)
      }
      formatted = formatted.replace(/[^\w ]+/g, '').replace(/ +/g, '-')
      return formatted
    },
    deepClone(obj) {
      return clone(obj)
    },

    getPercentage(value, total) {
      if (total > 0) {
        return Math.round(value / total * 100)
      }
      return 0
    },
    getRelativePercentage(values, value) {
      const total = values.reduce((acc, val) => acc + val.count, 0)
      return this.getPercentage(value.count, total)
    },
    localeDomain(user) {
      const locales = this.$root.$i18n['locales']
      const locale = locales.filter(l => l.code === (user || {}).preferences ? user.interfaceLanguage : 'en')[0]
      return locale.domain
    },
    checkoutUrl(itemId) {
      return `/checkout/${itemId}`
    }
  }
}
