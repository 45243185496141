<template>
  <section
    v-if="isAuthenticated && dataAvailable"
    class="followship"
  >
    <p class="panel-heading">
      {{$t('followedArtists')}}
    </p>
    <nav class="panel">
      <a v-for="(u, index) in followship.users" :key="index"
        @click="goProfile(u.artist.urlAlias)"
        :class="{ first: index === 0, 'panel-block': true }">
        <article class="media">
          <figure
            v-if="getAvatarPicture(u.artist)">
            <p class="image has-fix-width">
              <img
                class="image"
                :src="getAvatarPicture(u.artist)">
            </p>
          </figure>
          <div v-else
            class="image is-64h avatar-placeholder">
          </div>
          <div class="followship media-content">
            <div class="content">
              <p>
                <strong>{{u.artist.nickname}}</strong>
              </p>
            </div>
          </div>
        </article>
      </a>
    </nav>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState(['isAuthenticated', 'followship', 'mobileMenuOpen']),
    dataAvailable() {
      return this.followship &&
      this.followship.users &&
      this.followship.users.length > 0
    }
  },

  methods: {
    async goProfile(alias) {
      const locale = await this.$i18n.locale
      if (this.mobileMenuOpen) {
        this.$store.dispatch('toggleMobileMenuOpen')
      }
      this.$router.push({
        name: `id___${locale}`,
        params: {
          id: alias,
          scrollTo: {
            target: 'profile-navigation'
          }
        }
      })
    },
    getAvatarPicture(artist) {
      if (typeof artist.profile.imgs.avatar === 'string') {
        return artist.profile.imgs.avatar
      }
      if (!artist.profile.imgs.avatar.availableSizes) {
        return false
      }
      const availableSizes = artist.profile.imgs.avatar.availableSizes
      const image = availableSizes.find(size => size.name === 'avatar-xs')
      if (image) {
        return image.path
      }
      return false
    }
  }
}
</script>

<style lang="scss">
@import 'assets/sass/_variables';

.followship {
  @media screen and (min-width: $desktop) {
    padding-bottom: 1px;
  }
  &.media-content {
    margin-left: 10px !important;
  }
  .panel-heading {
    border-bottom: none;
  }
  .panel-block {
    min-height: 80px;
  }
  .is-64h {
    height: 64px;
    &.avatar-placeholder {
      height: 64px;
      width: 64px;
      background-color: #ccc;
    }
  }
  .has-fix-width {
    max-height: 64px;
    overflow-y: hidden;
    .image {
      width: 64px;
      height: auto;
    }
  }
}
</style>
