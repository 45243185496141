<template>
  <div class="overlay" :class="{ 'no-scroll': mobileMenuOpen }">
    <ul class="mobile-ul">
      <li>
        <nuxt-link :to="localePath({ name: 'portal-signup' })" v-if="!isAuthenticated"
          v-on:click.native.capture="emitLogOperation('signup')">{{ $t('signup') }}</nuxt-link>
      </li>
      <li>
        <nuxt-link :to="loginPath" v-if="!isAuthenticated" v-on:click.native.capture="emitLogOperation('login')">{{
          $t('login') }}</nuxt-link>
      </li>
      <li>
        <a v-if="isAuthenticated" href="/myprofile">{{ $t('hi') }} {{ user.nickname }}</a>
      </li>
      <li>
        <a v-if="isAuthenticated" @click="emitLogOperation('logout')">{{
          $t('logOut')
          }}</a>
      </li>
    </ul>

    <div class="mobile-search">
      <artist-search refs="artistSearch" name="mobileSearch" :mobile="true" />
    </div>

    <div class="followership" v-if="isAuthenticated">
      <portal-target name="mobileFollowship"></portal-target>
    </div>
  </div>
</template>

<style lang="scss">
@import 'assets/sass/_variables';

@media only screen and (max-width: $mobile) {
  .no-scroll {
    overflow-y: hidden;
    max-height: 100vh;
  }

  .mobile .navbar-menu {
    background-color: rgba(255, 255, 255, 0.95) !important;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }

  .searchactive {
    margin-bottom: 25px !important;
  }

  .overlay {
    height: 0;
    opacity: 0;
    transition: opacity 0.35s, height 0.35s;

    &.open {
      position: absolute;
      background-color: $gt-blue-light;

      display: flex;
      flex-flow: column nowrap;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      opacity: 1;
      transition: opacity 0.35s, height 0.35s;
      color: $text;
      text-shadow: $navigation-text-shadow;

      overflow-x: hidden;

      .mobile-ul {
        padding: 0;
        margin: 80px auto 20px auto;
        display: block;
        list-style: none;
        order: 0;

        li {
          display: inline-block;
          position: relative;
          opacity: 0;
          animation: fadeInRight 0.25s ease forwards;
          animation-delay: 0.35s;

          a {
            display: block;
            position: relative;

            text-decoration: none;
            overflow: hidden;
            font-size: 1.5rem;
            padding: 0 2rem;

            &:hover:after,
            &:focus:after,
            &:active:after {
              width: 100%;
            }

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0%;
              transform: translateX(-50%);
              height: 3px;
              background: var(--primary-color);
              transition: 0.35s;
            }

            &:nth-of-type(2) {
              animation-delay: 0.2s;
            }

            &:nth-of-type(3) {
              animation-delay: 0.25s;
            }

            &:nth-of-type(4) {
              animation-delay: 0.3s;
            }
          }
        }
      }

      .mobile-search {
        display: block;
        position: relative;
        z-index: 4;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
      }

      .followership {
        display: block;
        margin: 20px;
        background-color: #fff;
        max-height: calc(100vh - 210px);
        overflow-y: scroll;
      }
    }

    .followership {
      display: none;
    }

    .results {
      background-color: $background;

      .result .media-content {
        padding-right: 1.2em;
      }
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }

    100% {
      opacity: 1;
      left: 0;
    }
  }
}

.mobile-ul,
.mobile-language,
.mobile-search {
  display: none;
}
</style>

<script>
import { mapState } from 'vuex'
import artistSearch from '~/components/artist-search'

export default {
  components: { artistSearch },
  computed: {
    ...mapState(['user', 'artist', 'isAuthenticated', 'mobileMenuOpen']),
    loginPath() {
      return this.artist && this.artist.urlAlias
        ? `${this.localePath({ name: 'portal-login' })}?redirect=/${this.artist.urlAlias}`
        : this.localePath({ name: 'portal-login' })
    }
  },

  methods: {
    emitLogOperation(type) {
      this.$emit('logoperation', type)
    }
  }
}
</script>
