<template>
  <div @click.prevent="focusSearch">
    <div class="field has-addons is-search is-marginless bordered" :class="{ searchactive: q, focused: hasFocus || q }">
      <p class="control is-expanded has-icons-left has-pointer">
        <input name="q" @blur="onblurSearch" ref="searchinput" autocomplete="off" v-model="q" type="text"
          :placeholder="$t('search')" class="input search is-medium searchinput has-pointer"
          @keyup.esc="searchkeys('clear')" @keyup.up="searchkeys('up')" @keyup.down="searchkeys('down')"
          @keyup.enter="searchkeys('showDetails')" />
        <span class="icon is-large is-left searchicon">
          <i class="material-icons md-24">search</i>
        </span>
      </p>
      <p class="control">
        <button type="button" class="button is-medium is-white is-searchclear" v-show="q" @click.prevent="clear">
          <span class="icon is-large">
            <i class="material-icons">close</i>
          </span>
        </button>
      </p>
    </div>
    <div class="results" :class="{ shadowed: q, searchactive: q }">
      <div class="results-scroll-wrapper">
        <div class="result has-pointer" :key="index" v-for="(hit, index) in hits" data-qa="searchResultEntry">
          <article class="media" :class="{ 'is-active': index === currentRow }" @click="openProfile(hit.urlAlias)">
            <figure class="media-left">
              <p :class="{
                'hit-placeholder':
                  hit.profile &&
                  hit.profile.imgs &&
                  hit.profile.imgs.avatar === ''
              }" class="image is-64x64">
                <img class="avatar" :src="hit.profile.imgs.avatar" v-if="
                  hit.profile &&
                  hit.profile.imgs &&
                  hit.profile.imgs.avatar &&
                  hit.profile.imgs.avatar !== ''
                " />
              </p>
            </figure>
            <div class="media-content">
              <div class="content pb-10 pt-10">
                <b>{{ hit.nickname }}</b>
                <br />
                <small v-if="hit.profile && hit.profile.claim">
                  {{
                    hit.profile.claim
                  }}
                </small>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/sass/artist-search";
</style>

<script>
import { mapState } from 'vuex'
import algoliasearch from 'algoliasearch/lite'

const client = algoliasearch(process.env.ALGOLIA_APP, process.env.ALGOLIA_API)
const index = client.initIndex(process.env.ALGOLIA_INDEX)

export default {
  props: ['name', 'params', 'disabled', 'mobile', 'searchMaximized'],
  inject: ['exitSearch', 'focusedSearch'],

  data: () => ({
    q: '',
    hasFocus: false,
    currentRow: -1,
    hits: []
  }),

  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },

  watch: {
    async q(value) {
      if (value) {
        const result = await index.search(value)
        this.hits = result.hits
      } else {
        this.hits = []
      }
    },
    searchMaximized(maximized) {
      if (!maximized && !this.mobile) {
        this.clearAndExit()
      }
    }
  },

  methods: {
    clear() {
      this.q = ''
      this.hits = []
    },

    clearAndExit() {
      this.clear()
      this.hasFocus = false
      this.$refs.searchinput.blur()
      this.exitSearch()
    },

    focusSearch() {
      this.hasFocus = true
      this.$refs.searchinput.focus()
      this.focusedSearch(true)
    },

    onblurSearch() {
      this.hasFocus = false
    },

    async openProfile(alias) {
      const locale = this.$i18n.locale
      this.exitSearch()
      this.$router.push({
        name: `id___${locale}`,
        params: { id: alias }
      })
      this.currentRow = -1
      this.q = ''
      this.$refs.searchinput.blur()
    },

    searchkeys(stroke) {
      switch (stroke) {
        case 'up':
          if (this.currentRow >= 0) this.currentRow -= 1
          break
        case 'down':
          if (this.currentRow < this.hits.length - 1) this.currentRow += 1
          break
        case 'clear':
          if (this.q === '') this.exitSearch()
          else this.q = ''
          break
        case 'showDetails':
          if (this.currentRow >= 0) {
            this.openProfile(this.hits[this.currentRow].urlAlias)
          }
          break
      }
    }
  }
}
</script>

<style media="screen" lang="scss">
.is-searchclear {
  border-radius: 0px;
}

.searchinput {
  height: 49px;

  &:focus {
    border-width: 1px !important;
  }
}

.results {
  width: 100%;
  z-index: 2;

  .hit-placeholder {
    background-color: #ccc;
  }

  &.searchactive {
    position: absolute;
  }
}
</style>
