import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'

export default function (ctx, inject) {
  // Inject Raven to the context as $raven
  ctx.$raven = Raven
  inject('raven', Raven)

  Raven
    .config('https://c9e3d3e366754416848a025a0b72668a@sentry.uscreen.net/2', {"environment":"production"})
    .addPlugin(RavenVue, Vue)
    .install()
}
