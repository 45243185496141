const middleware = {}

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['logger'] = require('../middleware/logger.js')
middleware['logger'] = middleware['logger'].default || middleware['logger']

middleware['matomo'] = require('../middleware/matomo.js')
middleware['matomo'] = middleware['matomo'].default || middleware['matomo']

export default middleware
