<template>
<div v-show="value">
  <progress class="progress is-supersmall mt-10 mb-15"
    :class="progressClass"
    :value="score"
    max="5" />
  <p class="help">{{ warning }}</p>
  <p class="help has-text-grey"
    v-for="(suggestion, index) in suggestions" v-bind:key="index">{{ suggestion }}</p>
</div>
</template>

<script>
import debounce from 'tiny-debounce'
export default {
  props: ['value'],

  data: () => ({
    score: 0,
    warning: '',
    suggestions: []
  }),

  computed: {
    progressClass() {
      if (this.score < 2) return 'is-danger'
      if (this.score < 5) return 'is-warning'
      return 'is-success'
    }
  },

  watch: {
    value: debounce(async function(newVal) {
      if (newVal) {
        const response = await this.apiPost('/api/private/validate/password', {
          password: newVal
        })
        if (response) {
          this.score = 1 + response.score
          this.warning = this.translate(response.feedback.warning, 'warning')
          this.suggestions = response.feedback.suggestions.map(entry =>
            this.translate(entry, 'suggestion')
          )
        }
      } else {
        this.score = 0
        this.warning = ''
        this.suggestions = []
      }
    }, 100)
  },
  methods: {
    translate(str, key) {
      const locale = this.$i18n.locale || 'en'
      return this.$validator.dictionary.container[locale].custom
        .passwordMeter[key][str]
    }
  }
}
</script>

<style lang="scss">
.progress.is-supersmall {
  height: 0.4rem;
}
</style>
