<template>
  <div>
    <div v-if="isMenuOpen" id="backdrop" @click.prevent="closeMenu"></div>

    <button id="colorMenuButton" :title="$t('colors.title')" @click.prevent="toggleMenu" class="button is-primary"
      :class="{ 'open is-secondary': isMenuOpen }">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="lucide lucide-paintbrush-vertical">
        <path d="M10 2v2" />
        <path d="M14 2v4" />
        <path d="M17 2a1 1 0 0 1 1 1v9H6V3a1 1 0 0 1 1-1z" />
        <path
          d="M6 12a1 1 0 0 0-1 1v1a2 2 0 0 0 2 2h2a1 1 0 0 1 1 1v2.9a2 2 0 1 0 4 0V17a1 1 0 0 1 1-1h2a2 2 0 0 0 2-2v-1a1 1 0 0 0-1-1" />
      </svg>
    </button>

    <div id="colorMenu" v-if="isMenuOpen">
      <h3 class="title is-4" style="color: #000;">{{ $t('colors.title') }}</h3>
      <p class="pb-10">{{ $t('colors.description') }}</p>
      <div class="is-flex is-flex-direction-column">
        <colorPicker @color-changed="handleColorChange" :colorName="'primary'" :label="$t('colors.primaryAccent')"
          :value="profileColorStyles.primary" />
        <colorPicker @color-changed="handleColorChange" :colorName="'secondary'" :label="$t('colors.secondaryAccent')"
          :value="profileColorStyles.secondary" class="mt-10" />
        <colorPicker @color-changed="handleColorChange" :colorName="'tertiary'" :label="$t('colors.tertiaryAccent')"
          :value="profileColorStyles.tertiary" class="mt-10" />
        <hr class="mt-10 mb-0" />
        <h3 class="title is-6 mt-10 mb-0" style="color: #000;">{{ $t('colors.backgrounds') }}</h3>
        <colorPicker @color-changed="handleColorChange" :colorName="'bodyBackground'" :label="$t('colors.page')"
          :value="profileColorStyles.bodyBackground" class="mt-10" />
        <colorPicker @color-changed="handleColorChange" :colorName="'productBackground'" :label="$t('colors.product')"
          :value="profileColorStyles.productBackground" class="mt-10" />
        <colorPicker @color-changed="handleColorChange" :colorName="'tilesBackground'" :label="$t('colors.tiles')"
          :value="profileColorStyles.tilesBackground" class="mt-10" />
      </div>

      <div class="mt-20 is-flex is-justify-content-space-between">
        <button class="button same-min-width is-primary" @click.prevent="save">{{ $t('colors.save') }}</button>
        <button class="button same-min-width is-secondary" @click.prevent="reset">{{ $t('colors.reset') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import colorPicker from '~/components/color-picker'

const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

export default {
  name: 'ColorMenu',
  components: { colorPicker },
  data() {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    ...mapState(['profileColorStyles'])
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    handleColorChange: debounce(function ({ colorName, value }) {
      this.$store.commit('setProfileColor', { colorName, value })
    }, 200), // Adjust the delay as needed
    reset() {
      this.$store.dispatch('resetProfileColorStyles')
    },
    async save() {
      await this.$store.dispatch('saveProfileColorStyles')
      this.toggleMenu()
    }
  }
}
</script>

<style lang="scss">
#backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

#colorMenuButton {
  position: fixed;
  z-index: 1000;
  bottom: 8px;
  left: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;

  .lucide-paintbrush-vertical {
    animation-name: rotateBrushClose;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.open {
    .lucide-paintbrush-vertical {
      animation-name: rotateBrushOpen;
    }
  }
}

@keyframes rotateBrushOpen {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(45deg);
  }
}

@keyframes rotateBrushClose {
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(0deg);
  }
}

#colorMenu {
  position: fixed;
  z-index: 1000;
  bottom: 80px;
  left: 20px;
  width: 300px;
  color: black;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  button.same-min-width {
    min-width: 100px;
  }
}
</style>
