<template>
  <no-ssr>
    <div v-if="!this.isAuthenticated">
      <div class="social-button-container mt-10">
        <div class="social-button">
          <vue-facebook-login v-if="!showRegistrationForm" tabindex="1" app-id="235969604174559"
            :login-options="{ scope: 'email' }" v-model="fbModel" @login="handleLogin"
            @sdk-init="handleFacebookSdkInit" />
        </div>
        <div class="social-button">
          <vue-google-login v-if="!showRegistrationForm" tabindex="2" :params="{
            client_id: '79115176131-a6rbi8sl5hg30sm6s6rdtkjht0adbfh7.apps.googleusercontent.com',
          }" :renderParams="{
              width: 207,
              height: 36,
              longtitle: true
            }" :login-options="{ scope: 'email' }" :onSuccess="handleLogin" :onFailure="handleGoogleFailure" />
        </div>
      </div>

      <socialRegistrationForm v-if="showRegistrationForm" :modalRegistration="modalRegistration" :user="user"
        @valid="submitRegistration" @cancelled="cancelRegistration"></socialRegistrationForm>

      <div v-if="divider && !showRegistrationForm" class="is-divider mb-20" :data-content="$t('or')">
      </div>
    </div>
    <div v-else></div>
  </no-ssr>
</template>

<script>
import modal from '~/components/modal'
import socialRegistrationForm from '~/components/social-registration-form'
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'facebookLoginComponent',
  serverCacheKey: () => 'social-login',
  props: ['from', 'params', 'divider', 'modalRegistration', 'returnRouteObject'],
  components: { modal, socialRegistrationForm },
  data: () => ({
    email: '',
    nickname: '',
    user: null,
    showRegistrationForm: false,
    fbModel: {},
    fbScope: {},
    accessToken: false,
    authType: false
  }),

  async mounted() {
    if (!this.isAuthenticated) {
      this.logoutSocialAccounts()
    }
  },
  computed: {
    emailValidation() {
      return `required|validEmailAddress|uniqueEmailAddress:${this.user.id}`
    },
    nicknameValidation() {
      return `required|uniqueNickname:${this.user.id}`
    },
    apiEndpoint() {
      if (this.authType === 'facebook') {
        return '/api/private/authenticateFacebook'
      }
      if (this.authType === 'google') {
        return '/api/private/authenticateGoogle'
      }
      return ''
    },
    ...mapState(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['login', 'storeReturnRouteObject', 'storeRedirectPath', 'setFb']),
    async submitRegistration(data) {
      const result = await this.apiPost(this.apiEndpoint, {
        access_token: this.accessToken,
        email: data.email,
        nickname: data.nickname,
        updated: true
      })
      if (result) {
        this.submitLogin(result)
      }
    },
    logoutSocialAccounts() {
      if (this.authType === 'facebook') {
        if (this.fbScope) this.fbScope.logout()
      } else if (this.authType === 'google') {
        Vue.GoogleAuth.then(auth2 => {
          auth2.signOut()
        })
      }
    },
    close() {
      this.logoutSocialAccounts()
      if (!this.params || !this.params.isWizard) {
        this.$emit('close')
      }
    },

    async cancelRegistration() {
      this.logoutSocialAccounts()
      this.$root.$emit('logout')
      this.showRegistrationForm = false
      this.$emit('cancelled', false)
      this.$emit('registration', false)
    },
    submitLogin(data) {
      data.from = this.from

      if (this.$route.query.redirect) {
        this.storeRedirectPath(this.$route.query.redirect)
      }

      if (this.params && this.params.returnRouteObject) {
        this.storeReturnRouteObject(this.params.returnRouteObject)
      }
      this.login(data)
      this.$root.$emit('refreshAPI')
      this.$emit('logged')
    },
    async queryAuthentication(data) {
      if (!data) return false
      if (data.status === 'connected' && data.authResponse) {
        this.authType = 'facebook'
        this.accessToken = data.authResponse.accessToken
        return this.apiPost(this.apiEndpoint, {
          access_token: this.accessToken
        })
      }
      const keys = Object.keys(data)
      for (const key of keys) {
        if (
          typeof data[key] === 'object' &&
          data[key] !== null &&
          data[key].idpId === 'google' &&
          data[key].access_token
        ) {
          this.authType = 'google'
          this.accessToken = data[key].access_token
          return this.apiPost(this.apiEndpoint, {
            access_token: this.accessToken
          })
        }
      }
      return false
    },
    handleGoogleFailure(data) {
      console.log('google failure', data)
    },
    async handleLogin(data) {
      const authData = await this.queryAuthentication(data)
      if (!authData || !authData.user) return false

      if (
        !authData.user.socialData.nickname &&
        !authData.user.socialData.email
      ) {
        this.showRegistrationForm = false

        this.logoutSocialAccounts()

        return this.submitLogin(authData)
      }
      // newly registered account (confirm fields)
      this.user = authData.user

      this.showRegistrationForm = true
      this.$emit('registration', true)
    },
    async handleFacebookSdkInit(data) {
      this.FB = data.FB
      this.fbScope = data.scope
      if (!this.isAuthenticated) {
        data.scope.logout()
      }
    }
  }
}
</script>
<style lang="scss">
.social-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .social-button {
    max-width: 215px;
    width: 215px;

    .v-facebook-login {
      span {
        white-space: nowrap !important;
      }
    }
  }

  .social-button+.social-button {
    margin-top: 10px;
  }

  @media screen and (min-width: 478px) {
    .social-button+.social-button {
      margin-top: 0px;
    }
  }
}

@media screen and (min-width: 450px) {
  .modal-card {
    .social-button+.social-button {
      margin-top: 0px !important;
    }
  }
}

.abcRioButton {
  box-shadow: 0px !important;
  border-radius: 4px !important;

  .abcRioButtonIcon {
    padding-left: 18px !important;
    padding-right: 0px !important;
  }
}
</style>
