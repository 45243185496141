import Vue from 'vue'
import Api from '~/mixins/api'

Vue.mixin(Api)

export default ({ app }, inject) => {
  app.apiPost = Api.methods.apiPost
  app.apiPut = Api.methods.apiPut
  app.apiGet = Api.methods.apiGet
  app.apiDelete = Api.methods.apiDelete
}
