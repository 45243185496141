import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState(['profileColorStyles']),
    userColorStyles() {
      const { primary, secondary, tertiary, bodyBackground, productBackground, tilesBackground } = this.profileColorStyles
      return {
        '--primary-color': primary,
        '--secondary-color': secondary,
        '--tertiary-color': tertiary,
        '--primary-text-color': this.yiq(primary) ? '#000' : '#fff',
        '--secondary-text-color': this.yiq(secondary) ? '#000' : '#fff',
        '--tertiary-text-color': this.yiq(tertiary) ? '#000' : '#fff',
        '--body-background-color': bodyBackground,
        '--body-background-text-color': this.yiq(bodyBackground) ? '#000' : '#fff',
        '--tiles-background-color': tilesBackground,
        '--tiles-background-text-color': this.yiq(tilesBackground) ? '#000' : '#fff',
        '--product-background-color': productBackground,
        '--product-background-text-color': this.yiq(productBackground) ? '#000' : '#fff'
      }
    }
  },
  methods: {
    yiq(color) {
      color = color.replace('#', '')
      const r = parseInt(color.substr(0, 2), 16)
      const g = parseInt(color.substr(2, 2), 16)
      const b = parseInt(color.substr(4, 2), 16)
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
      return (yiq >= 128)
    }
  }
}
